
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































.campaign-details {
  transition: padding $--clb-transition-time ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @apply tw-pt-layout-3;

  &-tabs {
    z-index: 0;
  }

  &__content {
    width: 100%;
    min-height: 60vh;
  }

  .campaign-details-tabs {
    border-radius: 0 0 0 4px;
  }

  .el-tabs {
    .el-tabs__header {
      margin-bottom: $--clb-layout-1;
    }

    .el-tabs__item {
      height: $--clb-layout-4;
      line-height: $--clb-layout-4;
      font-size: $--clb-font-size-base;
      color: $--clb-color-grey__grey-darker;

      &.is-active {
        color: $--clb-color__headings;
      }
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: $--clb-layout-4;
    }

    .el-tabs__nav {
      .el-tabs__active-bar {
        height: 3px;
      }
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    &.freeze {
      .el-tabs__active-bar {
        transition: none;
      }
    }
  }
}

.app-header__portal .campaign-details {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;

  &-tabs {
    padding: 0 $--clb-mobile-padding;
    width: 100%;
    transition: $--all-transition;

    .el-tabs__header {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $--sm) {
  .app-header__portal .campaign-details-tabs {
    padding: 0 $--clb-layout-4;
  }
}

@media (min-width: $--md) {
  .campaign-details {
    transition: margin $--clb-transition-time ease, padding $--clb-transition-time ease;

    .el-tabs__header {
      margin-bottom: $--clb-layout-2;
    }
  }

  .app-header__portal .campaign-details-tabs {
    max-width: $--clb-app-content-width;
    margin: 0 auto;
    padding: 0 $--clb-layout-2 + $--clb-mobile-padding;
  }
}

@media (min-width: $--lg) {
  .app-header__portal .campaign-details-tabs {
    padding: 0 $--clb-layout-4 + $--clb-mobile-padding;
  }
}
